import http from '@services/httpService'
import { OutstandingReportModel, IOutstandingReport } from '@models/finance/outstandingReportModel'
import { notifySuccess, processFromToParams } from '@lib/helper'
import { LNotification } from '@lib/abpUtility'
import { downloadFile } from '@lib/helperFile'

class OutstandingReportService {
  public async create(body) {
    const result = await http.post('api/services/app/RemovalRequestOutstanding/CreateOutstandingReportRequest', body)
    notifySuccess(LNotification('SUCCESS'), LNotification('SAVING_SUCCESSFULLY'))
    return result.data.result
  }

  public async update(body) {
    const result = await http.put('api/services/app/RemovalRequestOutstanding/Update', body)
    notifySuccess(LNotification('SUCCESS'), LNotification('SAVING_SUCCESSFULLY'))
    return result.data.result
  }

  public async updateSortList(body: any) {
    const res = await http.post('api/services/app/TruckTypes/SortList', body)
    return res.data.result
  }

  public async activateOrDeactivate(id, isActive) {
    const result = await http.post(
      'api/services/app/RemovalRequestOutstanding/Active',
      { id },
      { params: { isActive } }
    )
    notifySuccess(LNotification('SUCCESS'), LNotification('SAVING_SUCCESSFULLY'))
    return result.data.result
  }

  public async delete(id) {
    const result = await http.delete('api/services/app/RemovalRequestOutstanding/Delete', { params: { id } })
    return result.data
  }

  public async get(id): Promise<any> {
    const result = await http.get('api/services/app/RemovalRequestOutstanding/Get', { params: { id } })
    return OutstandingReportModel.assign(result.data.result || {})
  }

  public async checkExist(code): Promise<any> {
    const result = await http.post('api/services/app/RemovalRequestOutstanding/CheckExistCode', null, {
      params: { code }
    })
    return result.data.result
  }

  public async filter(params): Promise<any> {
    const res = await http.get('api/services/app/RemovalRequestOutstanding/GetAll', { params: params })
    const result = res.data.result
    result.items = OutstandingReportModel.assigns(result.items).map((item, index) => {
      item.orderNumber = params.skipCount + index + 1
      return item
    })
    return result
  }

  public async getAll(params): Promise<IOutstandingReport[]> {
    const res = await http.get('api/services/app/RemovalRequestOutstanding/GetLists', { params })
    return OutstandingReportModel.assigns(res.data.result || [])
  }

  public async cancelRequest(body) {
    const result = await http.post('api/services/app/RemovalRequestOutstanding/CancelRequest', body)
    notifySuccess(LNotification('SUCCESS'), LNotification('CANCEL_SUCCESSFULLY'))
    return result.data.result
  }

  public async export(filters: any): Promise<any> {
    const params = processFromToParams(filters)

    const res = await http.get('api/Export/ExportOutstanding', {
      params,
      responseType: 'blob'
    })
    downloadFile(res.data, 'outstanding-report.xlsx')
  }
}

export default new OutstandingReportService()
