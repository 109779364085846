import { UserModel } from '@models/user/IUserModel'
import { v4 as uuid } from 'uuid'
import dayjs from '@lib/dayjs'

export interface IOutstandingReport {
  code?: string
  description?: any
  isActive?: boolean
  guid?: string
  user?: UserModel
  balanceAmount?: number
  statusId?: number
  statusCode?: string
  status?: string
  nameId?: string
}

export class OutstandingReportModel implements IOutstandingReport {
  orderNumber?: number
  guid?: string
  status?: string

  createdAt?: Date

  constructor() {
    this.guid = uuid()
  }

  public static assign(obj) {
    if (!obj) return undefined

    const newObj = Object.assign(new OutstandingReportModel(), obj)
    newObj.createdAt = obj.removalRequest?.createdAt ? dayjs(obj.removalRequest?.createdAt) : undefined
    newObj.status = obj.status?.toString()
    return newObj
  }

  public static assigns<T>(objs) {
    const results: any[] = []
    ;(objs || []).forEach((item) => results.push(this.assign(item)))
    return results
  }
}
